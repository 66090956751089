import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import { APP_DEFAULT_PATH_BASE_URL } from '../../config';

const DocPermissionGuard = ({ children }) => {
  const [isDocumentVerified, setIsDocumentVerified] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDocumentStatus = async () => {
      try {
        const userDetails = localStorage.getItem('user');
        if (!userDetails) {
          setIsDocumentVerified(false);
          setLoading(false);
          return;
        }

        const documentDetails = JSON.parse(userDetails);
        const response = await axios.get(`${APP_DEFAULT_PATH_BASE_URL}api/company/document-verify-check`, {
          params: { userId: documentDetails._id },
        });

        const documentStatus = response.data.companyUser?.[0]?.documentVerify;
        const isVerified = Number(documentStatus) === 1;
        setIsDocumentVerified(isVerified);
      } catch (error) {
        console.error('Error fetching document status:', error);
        setIsDocumentVerified(false);
      } finally {
        setLoading(false);
      }
    };

    fetchDocumentStatus();
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Or any other loading indicator
  }

  if (!isDocumentVerified) {
    return <Navigate to="/maintenance/404_forbidden" replace />;
  }

  return children;
};

export default DocPermissionGuard;