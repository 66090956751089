import { lazy } from 'react';

// project-imports
import GuestGuard from 'utils/route-guard/GuestGuard';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';

// render - login
const AuthLogin = Loadable(lazy(() => import('pages/auth/auth1/login')));
const AuthRegister = Loadable(lazy(() => import('pages/auth/auth1/register')));
const AuthForgotPassword = Loadable(lazy(() => import('pages/auth/auth1/forgot-password')));
const AuthCheckMail = Loadable(lazy(() => import('pages/auth/auth1/check-mail')));
const AuthResetPassword = Loadable(lazy(() => import('pages/auth/auth1/reset-password')));
const AuthCodeVerification = Loadable(lazy(() => import('pages/auth/auth1/code-verification')));
const AuthSignInOtp = Loadable(lazy(()=> import('pages/auth/auth1/AdminMobileverify')));

// ==============================|| AUTH ROUTES ||============================== //

const LoginRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <GuestGuard>
          <CommonLayout />
        </GuestGuard>
      ),
      children: [
        // {
        //   path: '/',
        //   element: <AuthLogin />
        // },
        {
          path: 'admin',
          element: <AuthLogin />
        },
        {
          path: 'admin/register',
          element: <AuthRegister />
        },
        {
          path: 'admin/forgot-password',
          element: <AuthForgotPassword />
        },
        {
          path: 'admin/check-mail',
          element: <AuthCheckMail />
        },
        {
          path: 'admin/reset-password/:userId',
          element: <AuthResetPassword />
        },
        {
          path: 'admin/code-verification',
          element: <AuthCodeVerification />
        },
        {
          path: 'admin/send-mobile-otp',
          element: <AuthSignInOtp />
        }
      ]
    }
  ]
};

export default LoginRoutes;
